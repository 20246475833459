
import {defineComponent, ref, computed, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {LegalPairs, TypeResources} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object as PropType<LegalPairs.LegalPair>,
			required: true
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, setValues, values} = useForm();

		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);

		const { value: selectedTypeResource1, errorMessage: typeResourceError1 } = useField<TypeResources.TypeResource>('selectedTypeResource1', 'required');
		function selectTypeResource1(selected: TypeResources.TypeResource) {
			selectedTypeResource1.value = selected ?? {};
		}

		const { value: selectedTypeResource2, errorMessage: typeResourceError2 } = useField<TypeResources.TypeResource>('selectedTypeResource2', 'required');
		function selectTypeResource2(selected: TypeResources.TypeResource) {
			selectedTypeResource2.value = selected ?? {};
		}

		const { value: master_resource_position, errorMessage: masterResourcePositionError } = useField<string>('master_resource_position', 'required|decimal');

		const if_friendly = ref(false);

		const set = () => {
			setValues({
				master_resource_position: String(props.item.master_resource_position),
			});

			if(props.item.id_type_res1) {
				const findTypeResource = typeResources.value.find(typeResource => typeResource.id === props.item.id_type_res1);

				if(findTypeResource)
					selectedTypeResource1.value = findTypeResource;
			}

			if(props.item.id_type_res2) {
				const findTypeResource = typeResources.value.find(typeResource => typeResource.id === props.item.id_type_res2);

				if(findTypeResource)
					selectedTypeResource2.value = findTypeResource;
			}

			if_friendly.value = props.item.if_friendly;
		}

		set();

		watch(() => props.open, () => set());

		const isLoading = ref(false);
		const service = injectOrThrow<LegalPairs.LegalPairService>('LegalPairs.LegalPairService');

		const edit = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.edit({
					id: props.item.id,
					id_type_res1: selectedTypeResource1.value.id,
					id_type_res2: selectedTypeResource2.value.id,
					master_resource_position: values.master_resource_position,
					if_friendly: if_friendly.value
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.edited", { name: ucFirst(t("dictionary.legalPair")) })});
				emit("item-changed");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = async () => {
			emit('dialog-closed');
			handleReset();
		};

		return {
			t,
			typeResources,
			selectedTypeResource1, typeResourceError1, selectTypeResource1,
			selectedTypeResource2, typeResourceError2, selectTypeResource2,
			master_resource_position, masterResourcePositionError,
			if_friendly,
			isLoading, edit, closeModal
		}
	}
})
