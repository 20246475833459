
import {defineComponent, computed, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import DataTable from '@/components/tables/DataTable.vue';
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import {LegalPairs, TypeResources} from "@/models";
import {useStore} from 'vuex';

export default defineComponent({
	emits: ["row-clicked", "page-changed", "item-removed"],
	components: {
		DataTable,
		DataTableHeader
	},
	props: {
		list: {
			type: Object as PropType<LegalPairs.LegalPairList>,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const headers = computed(() => [
			{ text: `${t("dictionary.left")} ${t("dictionary.typeResource")}`, value: 'resource_name1' },
			{ text: `${t("dictionary.right")} ${t("dictionary.typeResource")}`, value: 'resource_name2' },
			{ text: t("dictionary.masterResourcePosition"), value: 'master_resource_position' },
			{ text: t("dictionary.ifFriendly"), value: 'if_friendly' },
			{ classCell: 'pl-0', icon: {name: 'basket', title: `${t("button.remove")} ${t("dictionary.legalPair")}`, action: (item: LegalPairs.LegalPair) => emit("item-removed", item)}, width: "1%" },
		])

		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);

		const items = computed(() => props.list.items.map(item => {
			const findResource = (id: number) => typeResources.value.find((typeResource: TypeResources.TypeResource) => typeResource.id === id);

			return {
				...item,
				resource_name1: findResource(item.id_type_res1)?.name,
				resource_name2: findResource(item.id_type_res2)?.name
			}
		}));

		return { headers, items }
	}
})
